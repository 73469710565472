import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import ManageClinic from "./ManageClinic";
import { useCODData } from "../../context/CODDataContext";

const MainMenu = ({ providerData, clinicData }) => {
  const location = useLocation();
  const { isWaiting, isSafWaiting, isSoNotComplete } = useCODData();

  const showAlert =
    (isWaiting || isSafWaiting || isSoNotComplete) &&
    !location.pathname.includes("/consultations") &&
    !location.pathname.includes("/consultation");

  return (
    <>
      {providerData?.avialableForOthers === true ? (
        <Link
          to="/dashboard"
          className={
            location.pathname.includes("/dashboard") ? "sidebar_active" : ""
          }
        >
          {isWaiting && <div className={showAlert ? "red__alert" : ""}></div>}
          Home
        </Link>
      ) : (
        <Link
          to={
            providerData?.eligibleForExpertAnalysis
              ? "/second-opinion"
              : "/skincare-saf"
          }
          className={
            location.pathname.includes(
              providerData?.eligibleForExpertAnalysis
                ? "/second-opinion"
                : "/skincare-saf"
            )
              ? "sidebar_active"
              : ""
          }
        >
          {(isSafWaiting || isSoNotComplete) && (
            <div className={showAlert ? "red__alert" : ""}></div>
          )}
          Home
        </Link>
      )}
      <Link
        to="/my-consultations"
        className={
          location.pathname.includes("/my-consultations")
            ? "sidebar_active"
            : ""
        }
      >
        My Consultations
      </Link>
      {providerData?.skincareSAF === true &&
        providerData?.avialableForOthers === true && (
          //! Display only if the provider has access to skincare saf
          <Link
            to="/skincare-saf"
            className={
              location.pathname.includes("/skincare-saf")
                ? "sidebar_active"
                : ""
            }
          >
            {isSafWaiting && (
              <div className={showAlert ? "red__alert" : ""}></div>
            )}
            Skincare Case Review
          </Link>
        )}
      {providerData?.eligibleForExpertAnalysis &&
        providerData?.avialableForOthers && (
          <Link
            to="/second-opinion"
            className={
              location.pathname.includes("/second-opinion")
                ? "sidebar_active"
                : ""
            }
          >
            {isSoNotComplete && (
              <div className={showAlert ? "red__alert" : ""}></div>
            )}
            Second Opinion
          </Link>
        )}
      {providerData?.secondOpinionAdmin && (
        <Link
          to="/home-care"
          className={
            location.pathname.includes("/home-care") ? "sidebar_active" : ""
          }
        >
          Home Care
        </Link>
      )}
      {/* {providerData?.hasAccesstoDermaCare === true && ( //! Display only if the provider has access to dermacare
        <Link
          to="/dermacare"
          className={
            location.pathname.includes("/dermacare") ? "sidebar_active" : ""
          }
        >
          Dermacare
        </Link>
      )} */}
      <Link
        to="/calander"
        className={
          location.pathname.includes("/calander") ? "sidebar_active" : ""
        }
      >
        Calendar
      </Link>
      <Link
        to="/billing"
        className={
          location.pathname.includes("/billing") ? "sidebar_active" : ""
        }
      >
        Billing & Payments
      </Link>
      <Link
        to="/patients"
        className={
          location.pathname.includes("/patients") ? "sidebar_active" : ""
        }
      >
        Patients
      </Link>
      {clinicData?.clinicProvider?.length > 0 && (
        <ManageClinic providerData={providerData} clinicData={clinicData} />
      )}
    </>
  );
};

export default MainMenu;
